.counterparties__container {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: end;
  padding: 60px;
  gap: 60px;
}

.counterparties__table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  border: 1px solid #5F5F61;

  thead {
    background: #EEE;

    color: var(--day-grey-70-secondary, #5F5F61);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  tbody {
    color: var(--day-grey-70-secondary, #5F5F61);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  thead tr th {
    padding: 20px;
    border: 1px solid #5F5F61;
  }

  tbody tr td {
    padding: 16px 20px;
    border: 1px solid #5F5F61;
  }
}

.counterparties__button {
  width: fit-content;

  border-radius: 12px;
  padding: 10px;
  border: 1px solid var(--day-alpha-grey-32, rgba(18, 18, 18, 0.32));
  background: var(--day-white, #FFF);

  color: var(--day-black, #000);
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;
}