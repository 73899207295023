.right-sidemenu__wrapper {
  padding: 30px 23px 0 0;
  max-width: 280px;

  h3 {
    margin: 0;
    text-align: left;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: -0.18px;
    color: var(--theme-bold-color);
  }

  .right-sidemenu__calendar {
    height: 272px;
    margin-bottom: 30px;
    border-radius: 20px;
    border: 0.5px solid #CBCBCB;
    background: #FFF;
    padding: 20px;
    box-sizing: border-box;
  }

  .right-sidemenu__tariff {
    margin-bottom: 30px;
    border: 1px solid gray;
    border-radius: 24px;
    padding: 20px;
    text-align: left;

    img {
      max-width: 80%;
      margin-bottom: 16px;
    }

    p {
      margin: 0 0 8px 0;
      color: #FFF;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.25rem;
    }

    h4 {
      margin: 0 0 16px 0;
      color: #FFF;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 2rem;
      letter-spacing: -0.24px;
    }

    a {
      padding: 6px 16px;
      text-align: center;
      border-radius: 12px;
      border: 0.5px solid #F6F6F6;
      border-radius: 12px;
      border: 0.5px solid #F6F6F6;
      color: #FFF;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.3125rem;
      letter-spacing: -0.21px;
      text-decoration: none;
    }
  }

  .right-sidemenu__integration {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 1px solid gray;
    border-radius: 24px;
    padding: 20px;
    text-align: left;

    .right-sidemenu__integration--list {
      width: 100%;
      order: 3;
      gap: 5px;
      display: flex;
      flex-direction: column;
      padding-top: 20px;

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 15px;
        border: 0.5px solid #EEE;
        padding: 16px;

        p {
          margin: 0;
          color: var(--theme-bold-color);
        }

        img {
          padding: 8px;
          border-radius: 8px;
          border: 0.5px solid #A1A0A3;
          width: 16px;
          height: 16px;
        }
      }
    }

    .right-sidemenu__integration--icon {
      order: 2;
    }
  }

  .right-sidemenu__integration-button {
    outline: none;
    border: none;
    background: none;
  }
}