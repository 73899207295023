.claim-create__container {
    display: flex;
    flex-direction: row;
    width: 100vw;
}

.claim-create__sidemenu {
    width: 324px;
    height: 100vh;
}

.claim-create__view-container {
    width: calc(100vw - 324px);
    overflow: hidden;

    position: relative;
}

.claim-create__viewer {
    background: var(--settings-background);
    height: 90vh;

    display: flex;
    flex-direction: row;
}

.claim-viewer__document-container,
.claim-viewer__claim-container {
    width: calc(50% - 20px);
}

.claim-viewer__document-container iframe,
.claim-viewer__claim-container iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.claim-viewer__document-container.viewer__no-events,
.claim-viewer__claim-container.viewer__no-events {
    pointer-events: none;
}

.claim-viewer__separator {
    width: 40px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.claim-viewer__separator img {
    transition: box-shadow 0.2s ease-in-out;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    border-radius: 50%;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.36);
}

.claim-viewer__separator img:hover,
.claim-viewer__separator img:active {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
}

.claim-create__controls {
    display: flex;
    flex-direction: row;
    gap: 40px;
    background: var(--settings-background);
    height: 10vh;
}

.claim-create__controls > * {
    width: calc(50% - 5px);
    padding: 0 46px;
}

.controls__left {
    display: flex;
    justify-content: start;
    gap: 20px;
    align-items: center;
}

.control-button__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.control-button__container button {
    outline: none;
    border: none;
    padding: 10px 20px;
    line-height: 150%;

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.08px;
}

.control-button__container .button__send {
    border-radius: 12px;
    background: var(--day-primary-60-base, linear-gradient(236deg, #e3495a 0.15%, #b82d22 100%));
    color: var(--day-white, #fff);
}

.control-button__container .button__save-claim {
    border-radius: 12px;
    border: 0.5px solid var(--day-alpha-grey-32, rgba(18, 18, 18, 0.32));
    background: var(--day-white, #fff);
}

.control-button__question {
    position: relative;
}
