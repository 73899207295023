.dashboard__tariff-container {
  background: linear-gradient(260deg, #2c5364 -4.78%, #203a43 44.64%, #0f2027 97.25%);
  border: 1px solid var(--day-alpha-grey-32, rgba(18, 18, 18, 0.32));
  width: 80%;
  max-width: calc(950px - 100px);
  border-radius: 20px;
}

.tariff-container__content-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tariff-container__content-column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 40px;

  gap: 32px;
}

.tariff-container__text {
  color: white;
}

.tariff-container__welcome {
  color: white;
  width: fit-content;
  text-align: start;

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dashboard-content__features {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: start;
  align-items: start;
}

.dashboard-features__feature {
  color: white;
  display: flex;
  flex-direction: row;
  gap: 16px;
  font-size: 20px;
}

.dashboard__buttons-row {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  width: 100%;
  box-sizing: border-box;

  form {
    display: none;
  }
}

@media screen and (max-width: 1440px) {
  .dashboard__buttons-row {
    flex-direction: column;

    .dashboard__button {
      width: 100%;
    }
  }
}

.dashboard__content-wrapper {
  padding: 30px 50px 0 30px;
}

.dashboard__content-wrapper h2 {
  padding-top: 50px;
  margin-block-start: 0;
  margin-block-end: 0;
}

.dashboard__button {
  padding: 37px 108px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  align-items: center;

  width: calc(50% - 16px);

  border-radius: 30px;
  border: 0.5px solid var(--day-alpha-grey-32, rgba(18, 18, 18, 0.32));
  background: var(--dashboard-settings-background);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.03);

  cursor: pointer;

  outline: none;
}

@media screen and (max-width: 1280px) {
  .dashboard__button {
    padding: 38px;
  }
}

.dashboard__button:active,
.dashboard__button:focus {
  outline: none;
}

.dashboard-button__caption {
  color: var(--theme-bold-color);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.dashboard-button__caption,
.dashboard-button__image {
  pointer-events: none;
}

[data-theme="dark"] {
  .dashboard-button__image {
    filter: invert(1);
  }
}

.dashboard__modal-title {
  color: var(--theme-bold-color);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 132%; /* 23.76px */
  letter-spacing: -0.18px;
}

.dashboard__modal-load-area {
  width: 100%;
  display: flex;
  flex-direction: column;

  border-radius: 20px;
  border: 1px dashed var(--day-grey-50-tertiary, #a1a0a3);

  height: 400px;

  cursor: pointer;
}

.dashboard__modal-caption {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 16px;
}

.dashboard__modal-caption__text {
  color: var(--day-grey-70-secondary, #5f5f61);
  text-align: center;
  width: 50%;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.06px;
}

.dashboard__modal-caption__text.file__loaded {
  word-break: break-all;
}

.dashboard__modal-caption__text,
.dashboard__modal-caption__icon {
  user-select: none;
  pointer-events: none;
}

.dashboard__modal-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
}

.settings__modal-content_sign .dashboard__modal-buttons {
  margin-top: auto;
}

.crop-container {
  max-width: 100%;
}

.dashboard__modal-buttons .dashboard__menu-button {
  width: calc(50% - 8px);

  padding: 10px 20px;
  outline: none;
  border: none;

  border-radius: 12px;
}

.dashboard__menu-button.button__destination {
  color: var(--day-black, #000);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.08px;

  border-radius: 12px;
  border: 0.5px solid var(--day-alpha-grey-32, rgba(18, 18, 18, 0.32));
  background: var(--day-white, #fff);
}

.dashboard__menu-button.button__save {
  color: var(--day-white, #fff);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.08px;

  &.button__disabled {
    background: grey;
    opacity: 0.5;
    cursor: wait;
  }
}

.dashboard__modal-complete {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 24px;
  border-radius: 15px;
  border: 1px solid var(--button-back-border);
  background-color: var(--modal-background);
}

.dashboard__modal-input-group {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;

  width: 100%;
}

.dashboard__modal-select,
.dashboard__autoname-field {
  width: calc(100% - 32px);
  border-radius: 10px;
  border: 1px solid var(--button-back-border);
  padding: 10px 16px;
  background-color: var(--modal-background);
  color: var(--theme-bold-color);
}

.dashboard__autoname-field::placeholder {
  color: var(--theme-bold-color);
}

.dashboard__modal-select {
  width: 100%;
}

.dashboard__modal-select option {
  appearance: none;
  padding: 5px;
}

.dashboard__modal-name-caption {
  color: var(--subscription-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
  text-align: start;
}

.dashboard__modal-input-label {
  color: var(--theme-bold-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
}

.dashboard__modal-complete-text {
  color: var(--theme-bold-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.21px;
}

.dashboard__width-full > * {
  width: 100% !important;
}

.settings__modal-content.settings__modal-content-centered {
  align-items: center;
}

.dashboard__modal-progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 15px;
  border: 1px solid var(--button-back-border);
  background: var(--modal-background);
  padding: 16px 24px;
  box-sizing: border-box;
  margin: 20px 0;

  .progress-bar__percent {
    width: calc(100% - 50px);
    max-width: 260px;

    .progress-bar__percent--text {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.3125rem;
      letter-spacing: -0.21px;
      margin-right: 15px;
      color: var(--theme-bold-color);

      .progress-bar__paragraph {
        width: calc(100% - 20px);
      }

      p {
        margin: 0;
        max-width: 200px;
        word-wrap: break-word;
        text-align: left;
      }
    }

    progress {
      width: 100%;
      height: 4px;
      border-radius: 20px;
      background-color: #ddd;

      &::-webkit-progress-bar {
        border-radius: 20px;
        background-color: #ddd;
      }

      &::-webkit-progress-value {
        border-radius: 20px;
        background: var(--day-primary-60-base, linear-gradient(236deg, #e3495a 0.15%, #b82d22 100%));
      }
    }
  }
}

.dashboard__modal-progress-btn {
  padding: 10px 20px;
  border-radius: 12px;
  border: 0.5px solid rgba(18, 18, 18, 0.32);
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  cursor: pointer;
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: -0.08px;
  text-decoration: none;
}

.dashboard__monitoring {
  width: 100%;
  text-align: left;
  color: var(--theme-bold-color);

  h2 {
    margin-bottom: 12px;
  }

  .monitoring-item {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 200px;
    padding: 20px;
    width: 100%;
    border-radius: 20px;
    border: 0.5px solid #cbcbcb;
    background: var(--monitoring-item-background-color);
    margin-bottom: 22px;
    box-sizing: border-box;

    h3 {
      font-weight: 500;
      letter-spacing: -0.18px;
      margin: 0;
      font-size: 18px;
    }

    .monitoring-item__process {
      order: 3;
      width: 100%;
      margin-top: 20px;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;

      .monitoring-item__stage {
        background: var(--sidemenu-background);
        flex-basis: 32%;
        flex-grow: 1;
        border-radius: 20px;
        border: 0.5px solid var(--sidemenu-background);
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        box-sizing: border-box;
        align-items: flex-start;

        h4 {
          margin: 0;
          font-size: 1.125rem;
          font-weight: 500;
          line-height: 1.5rem;
          letter-spacing: -0.18px;
          width: calc(100% - 30px);
        }

        .monitoring-item__stage--info {
          display: flex;
          order: 2;
          margin-top: 20px;

          p {
            margin: 0;
            font-size: 1.125rem;
            font-weight: 500;
            line-height: 1.5rem;
            white-space: nowrap;
          }

          .monitoring-item__stage--separator {
            margin: 0 30px;
            width: 1px;
            height: 24px;
            background: #d9d9d9;
          }

          .monitoring-item__stage--sum {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            p:first-child {
              margin-right: 10px;
            }

            h5 {
              margin: 0;
              color: #5f5f61;
              font-size: 0.625rem;
              font-weight: 400;
              line-height: 0.8125rem;
              letter-spacing: -0.05px;
              width: 100%;
            }

            .monitoring-item__stage--sum-success,
            .monitoring-item__stage--sum-rejected {
              display: flex;
              flex-wrap: wrap;
              white-space: nowrap;
              width: 100%;
            }
          }

          .monitoring-item__stage--info-case {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .monitoring-item__stage--separator {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}

.dashboard__error {
  color: rgba(229, 119, 103, 0.9);
}

@media screen and (max-width: 1440px) {
  .dashboard__monitoring .monitoring-item .monitoring-item__process .monitoring-item__stage {
    p {
      font-size: 14px !important;
    }

    h4 {
      font-size: 18px !important;
    }

    .monitoring-item__stage--separator {
      margin: 0 10px !important;
    }
  }
}

@media screen and (max-width: 1020px) {
  .monitoring-item__stage--info {
    flex-direction: column !important;
    gap: 5px;

    > *:first-child {
      border-bottom: 1px solid #EEE;
    }
  }

  .monitoring-item__stage--separator {
    display: none !important;
  }
}