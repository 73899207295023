@mixin flexible {
  display: flex;
}

@mixin flexColumnJustifyStart {
  @include flexible;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

@mixin justifyStart {
  justify-content: start;
  align-items: center;
}

@mixin justifyBetween {
  justify-content: space-between;
  align-items: center;
}

@mixin flexRowJustifyStart {
  @include flexible;
  flex-direction: row;
  @include justifyStart;
}

@mixin flexRowJustifyBetween {
  @include flexible;
  flex-direction: row;
  @include justifyBetween;
}

@mixin fullSize {
  width: 100%;
}

@mixin fullSizeWithPadding {
  width: calc(100% - 60px);
}

@mixin fontSettingsDefault {
  font-family: Inter;
  font-style: normal;
  color: var(--theme-bold-color);
}

.settings__content-wrapper {
  padding-top: 50px;
  gap: 30px !important;

  @include fullSize;
  > h1 {
    @include fullSizeWithPadding;
    @include fontSettingsDefault;
    font-weight: 600;
    text-align: start;
    font-size: 24px;
    line-height: normal;

    margin: 0;
  }
}

.integrations__container {
  @include flexColumnJustifyStart;
  gap: 30px;
  padding: 0 30px 50px 30px;
  width: calc(100% - 60px);

  h1 {
    @include fontSettingsDefault;
    font-weight: 500;
    text-align: start;
    font-size: 18px;
    line-height: 132%; /* 23.76px */
    letter-spacing: -0.18px;
    margin: 0;
  }
}

.integrations {
  &__list {
    @include flexColumnJustifyStart;
    @include fullSize;
    max-width: 1264px;

    gap: 10px;
  }

  &__item {
    @include fullSize;
    @include flexRowJustifyBetween;

    gap: 20px;
    width: calc(100% - 40px);
    border-radius: 20px;
    border: 0.5px solid var(--day-grey-30, #CBCBCB);
    background: var(--day-white, #FFF);

    padding: 20px;
  }

  &__row {
    @include flexRowJustifyStart;
    gap: 20px;
  }

  &__button-save {
    @include fontSettingsDefault;

    padding: 16px 24px;
    outline: none;
    border-radius: 15px;
    border: 1px solid var(--day-alpha-grey-32, rgba(18, 18, 18, 0.32));
    background: var(--Linear, #E3495A);
    color: #FFF;

    font-size: 14px;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: -0.21px;

    width: 380px;
  }
}

.integration-item {
  &__status-icon {
    padding: 8px;
    width: 16px;
    height: 16px;
  }

  &__status-button {
    @include flexColumnJustifyStart;

    background: none;
    border-radius: 8px;
    border: 1px solid #A1A0A3;
    padding: 0;
    outline: none;

    * {
      pointer-events: none;
    }
  }
}

.integrations__settings-icon {
  border: none;
  outline: none;
  padding: 0;

  background: none;

  * {
    pointer-events: none;
  }
}

.integrations__form {
  width: 80%;
  margin: 0 auto;

  span {
    text-align: start;
    margin: 0;
  }
}

.integrations__form-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;

  justify-content: start;
  align-items: start;

  border-radius: 20px;
  border: 0.5px solid var(--day-grey-30, #CBCBCB);
  background: var(--day-white, #FFF);
  padding: 20px;
}

.integrations__form-input-group {
  display: flex;
  flex-direction: column;
  gap: 5px;

  width: 100%;
}

.integrations__form-input {
  padding: 10px;
  width: calc(100% - 20px);

  border-radius: 10px;
  outline: none;
  border: 1px solid var(--day-grey-30, #CBCBCB);
}

.integrations__form-input:active,
.integrations__form-input:focus {
  border: 1px solid var(--day-grey-30, #858585);
}

.integration__buttons-list {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 10px;
}

.integration__form-cancel,
.integration__form-submit {
  padding: 16px 24px;
  outline: none;
  border-radius: 15px;
  border: 1px solid var(--day-alpha-grey-32, rgba(18, 18, 18, 0.32));
  background: var(--Linear, #E3495A);
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.21px;
  cursor: pointer;
}

.integration__form-submit:disabled {
  opacity: 0.5;
  cursor: unset;
}

.integration__form-cancel {
  background: white;
  color: black;
}