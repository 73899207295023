:root {
    --claim-send-popup-overlay-background: #rgba(18, 18, 18, 0.32);

    --theme-background: white;
    --theme-bold-color: #000;
    --theme-thin-color: #5f5f61;

    --theme-button-color-disabled: #e5b4b5;
    --theme-button-color-enabled: var(--day-primary-60-base, linear-gradient(236deg, #e3495a 0.15%, #b82d22 100%));
    --theme-button-hover-color-enabled: linear-gradient(236deg, #be3e4c 0.15%, #912118 100%);
    --modal-save-hover-background: linear-gradient(236deg, #be3e4c 0.15%, #912118 100%);

    --theme-toggler-background: var(--day-primary-60-base, linear-gradient(236deg, #e3495a 0.15%, #b82d22 100%));
    --theme-toggler-container-background: #f6f6f6;

    --theme-input-background: white;
    --validator-background: #222124;
    --validator-color: white;

    --settings-user-background-color: #252525;

    --sidemenu-background: #fff;
    --settings-background: #fcfcfc;
    --dashboard-settings-background: #fcfcfc;

    --settings-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.05);
    --sidemenu-element-background: #eee;

    --button-exit-color: white;
    --button-exit-border: rgba(18, 18, 18, 0.32);
    --button-tariffs-border: rgba(18, 18, 18, 0.32);

    --theme-tariff-background: white;
    --theme-tariff-list-border: #eee;
    --theme-tariff-list-border-bold: #cbcbcb;
    --theme-tariff-list-border-selected: #222124;

    --theme-icon-filter: none;
    --theme-icon-filter-text: none;

    --user-data-links-border: #eee;

    --button-exit-hover-background: black;
    --button-exit-hover-color: white;
    --modal-background: #fff;
    --modal-input-color: #222124;
    --modal-input-background: #f6f6f6;
    --modal-save-background: linear-gradient(236deg, #e3495a 0.15%, #b82d22 100%);
    --modal-save-color: white;
    --modal-cancel-border: rgba(18, 18, 18, 0.32);

    --reset-color-bold: #000;
    --reset-color-thin: #222124;

    --subscription-color: black;
    --tariff-open-color: black;

    --arrow-back-color: #5f5f61;
    --arrow-back-filter: none;
    --button-back-border: rgba(18, 18, 18, 0.32);

    --modal-cancel-hover-color: white;
    --modal-cancel-hover-background: black;
    --button-exit-background: #252525;
    --button-exit-filter: none;

    --monitoring-item-background-color: #eee;
    --settings-modal-content-background-color: #fff;
    --monitoring-contact-row-background-color: #fff;
    --actions-button-disabled-opacity: 0.5;
    --actions-button-monitoring: rgba(18, 18, 18, 0.32);

    --scrollbar-thumb-background-color: rgba(18, 18, 18, 0.32);
    --search-background: #f6f6f6;
    --search-color: #161616;
    --monitoring-button-color: black;
    --monitoring-document-background: #f6f6f6;
    --filter-sort-border: var(--settings-background);

    --claim-edit-row-border: #cbcbcb;
    --claim-edit-violance-color: rgba(0, 0, 0, 0.5);
    --claim-edit-button-background: #fff;
    --claim-edit-button-red-background: #e3495a;
    --claim-edit-button-text-color: #000;

    --claim-send-block-border: #cbcbcb;
    --claim-send-popup-color: #222124;
    --claim-send-popup-button-border: #000;
    --claim-send-input-color: #222124;
    --claim-send-button-color: #333;
    --claim-send-button-border: #b3b3b3;

    --back-button-background: #f6f6f6;
    --border-button-add-obligation: 1px solid #b3b3b3;
    --background-button-add-obligation: #fff;
    --color-button-add-obligation: #333;
}

[data-theme="dark"] {
    --border-button-add-obligation: 1px solid white;
    --background-button-add-obligation: none;
    --color-button-add-obligation: white;

    --monitoring-document-background: #222124;
    --monitoring-button-color: #302f2f;
    --search-color: white;
    --search-background: #161616;

    --button-exit-background: #dadada;
    --button-exit-filter: invert(1);

    --modal-cancel-hover-color: black;
    --modal-cancel-hover-background: white;

    --arrow-back-filter: invert(1);
    --button-back-border: #302f2f;
    --arrow-back-color: #bababc;

    --tariff-open-color: white;
    --modal-cancel-border: #302f2f;
    --modal-input-background: transparent;
    --modal-background: #161616;
    --modal-input-color: #dcdbde;
    --modal-save-background: #ec5c48;
    --modal-save-hover-background: #e7422b;

    --modal-save-color: black;

    --theme-background: #18181a;
    --theme-bold-color: #fff;
    --theme-thin-color: #bababc;

    --theme-button-color-disabled: #5f5f61;
    --theme-button-color-enabled: #ec5c48;
    --theme-button-hover-color-enabled: #e7422b;

    --theme-input-background: transparent;
    --validator-background: #dcdbde;
    --validator-color: #161617;

    --sidemenu-background: #161617;
    --settings-background: #18181a;
    --dashboard-settings-background: #151617;

    --settings-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.05);
    --sidemenu-element-background: #222124;

    --button-exit-color: transparent;
    --button-exit-border: #f6f6f6;
    --button-tariffs-border: #f6f6f6;

    --theme-toggler-background: white;
    --theme-tariff-background: #161617;
    --theme-tariff-list-border: #1e1e1e;
    --theme-tariff-list-border-bold: #302f2f;
    --theme-tariff-list-border-selected: white;

    --theme-toggler-container-background: #222124;
    --theme-icon-filter: contrast(0);
    --theme-icon-filter-text: contrast(0.5);

    --user-data-links-border: #1e1e1e;

    --button-exit-hover-background: white;
    --button-exit-hover-color: black;

    --reset-color-bold: #fff;
    --reset-color-thin: #dcdbde;

    --subscription-color: #bababc;
    --monitoring-item-background-color: #222124;
    --settings-modal-content-background-color: #181819;
    --monitoring-contact-row-background-color: #151617;
    --actions-button-disabled-opacity: 0.5;
    --actions-button-monitoring: #302f2f;

    --scrollbar-thumb-background-color: #121212;

    --claim-send-input-color: #fff;
    --claim-send-button-color: #fff;
    --claim-send-button-border: #616161;
    --claim-send-block-border: #616161;
    --claim-send-popup-overlay-background: rgba(0, 0, 0, 0.8);
    --claim-send-popup-color: #bababc;
    --claim-send-popup-button-border: #f6f6f6;

    --claim-edit-row-border: #616161;
    --claim-edit-violance-color: #a1a0a3;
    --claim-edit-button-background: #616161;
    --claim-edit-button-red-background: #ec5c48;
    --claim-edit-button-text-color: #f6f6f6;

    --back-button-background: #161617;
}

@media screen and (max-width: 850px) {
    :root {
        --settings-background: white;
    }

    [data-theme="dark"] {
        --settings-background: #18181a;
    }
}

.hidden {
    display: none !important;
}

.width {
    &__full {
        width: 100% !important;

        .form__register {
            width: 30%;
        }

        @media screen and (max-width: 850px) {
            .form__register {
                width: calc(100% - 24px);
            }
        }
    }
}

.app__reset-container {
    width: 100vw;
    height: 100vh;
}

.app__reset-wrapper {
    display: flex;
    flex-direction: column;
    padding: 36vh 0;
}

.app__reset-wrapper .desktop__hidden {
    display: none;
}

.app__reset-title {
    padding-top: 60px;

    color: var(--reset-color-bold); // #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 132%;
    letter-spacing: -0.18px;
}

.app__reset-title::first-letter {
    text-transform: capitalize;
}

.app__reset-text {
    padding-top: 16px;

    color: var(--reset-color-thin); // #222124;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.app__reset-link {
    text-decoration: none;
    color: var(--reset-color-bold);
}

.app__reset-image {
    user-select: none;
    pointer-events: none;
}

@media screen and (max-width: 850px) {
    .app__reset-wrapper .desktop__hidden {
        display: inline;
    }

    .app__reset-wrapper {
        padding: 36vh 12px;
    }

    .app__reset-image {
        width: 213px;
        height: 33px;
    }
}

button {
    cursor: pointer;
}

.App {
    width: 100%;
    height: 100%;
    background: var(--theme-background);
}

.auth__wrapper,
.auth__container {
    width: 100%;
    height: 100vh;
}

.auth__wrapper {
    display: flex;
    flex-direction: row;
}

.auth__content {
    padding-top: 30vh;
    width: 40%;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.auth__side-background {
    width: 60%;
}

@media screen and (max-width: 850px) {
    .auth__side-background {
        display: none;
    }

    .auth__content {
        width: 100%;
        padding-top: 2rem;
    }

    .form__register {
        width: calc(100% - 24px);
    }
}

.form__name-caption {
    display: flex;
    gap: 16px;
    flex-direction: column;
    margin: 0 0;
    color: var(--theme-bold-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    letter-spacing: -0.06px;

    p {
        margin: 0;
        text-align: left;
    }
}

.dashboard__container,
.monitoring__container,
.settings__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    min-height: 100vh;

    background: var(--settings-background);
}

.sidemenu-theme__form-hidden {
    display: none;
}

.settings__title {
    margin: 0;
    width: 100%;
}

.monitoring__content-wrapper,
.dashboard__content-wrapper,
.settings__content-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10%;
    width: calc(100% - 324px);
}

.dashboard__content-wrapper {
    width: calc(100% - 404px - 303px);
}

.dashboard__content-wrapper {
    gap: 0;
}

.settings__content-wrapper.settings-content__tariffs {
    gap: 0;
}

.settings__title {
    padding-top: 50px;
    padding-bottom: 50px;
    color: var(--theme-bold-color);
}

.user-data__docs,
.user-data__row,
.settings__profile-container {
    display: flex;
    flex-direction: row;
}

.user-data__row {
    position: relative;
    align-items: center;
    gap: 16px;
}

.user-data__avatar {
    position: relative;
    z-index: 10;
}
.user-data__avatar:not(.avatar__edit):hover {
    position: relative;
    z-index: 6;
    filter: brightness(0.5);
}

.user-data__avatar.avatar__edit {
    position: absolute;
    left: 28px;
    z-index: 7;
    pointer-events: none;

    height: 24px;
    width: 24px;
}

.settings__profile-container {
    gap: 120px;
    flex-wrap: wrap;
    padding: 0 5%;
}

.settings__tariffs-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}

.user-data__avatar {
    cursor: pointer;

    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}

.user-data__info {
    display: flex;
    flex-direction: column;
    align-items: start;

    gap: 8px;

    width: calc(100% - 96px);
}

.settings-profile__user-data {
    display: flex;
    flex-direction: column;
    gap: 24px;

    padding: 50px;
    background: var(--sidemenu-background);
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.05);

    border-radius: 20px;
}

.user-data__docs {
    gap: 4px;
    width: 100%;
    justify-content: space-between;

    opacity: 0;
}

.user-data__links {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 4px;
    border-radius: 20px;

    padding: 8px 16px;
    border: 1px solid var(--user-data-links-border);

    color: var(--theme-bold-color);
}

.user-data__links img {
    width: 14px;
    height: 14px;
}

.user-data__name {
    color: var(--theme-bold-color);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 132%; /* 23.76px */
    letter-spacing: -0.18px;
}

.user-data__edit {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.user-data__edit-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;
}

.edit-field__field-content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    gap: 4px;
}

.edit-field__edit-button {
    margin-left: auto;
}

.field-content__caption {
    color: var(--theme-thin-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    letter-spacing: -0.06px;
}

.field-content__content {
    color: var(--theme-bold-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
}

.settings-profile__actions {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.settings-profile__action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    color: #5f5f61;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    user-select: none;
    cursor: pointer;
}

.settings-profile__action:hover {
    color: #ee705f;
}

.button__exit {
    padding: 14px 20px;
    background: var(--button-exit-color);
    border-radius: 12px;
    border: 1px solid var(--button-exit-border);

    color: var(--theme-bold-color);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.24px;
}

.button__edit {
    padding: 6px 16px;
    border-radius: 12px;
    outline: none;
    border: none;
    background: var(--theme-button-color-enabled);

    color: var(--day-white, #fff);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: -0.21px;
}

.button__edit:hover {
    background: var(--theme-button-hover-color-enabled);
}

.button__back {
    position: absolute;
    left: 40px;
    top: 48px;
    border-radius: 12px;
    padding: 14px 20px;

    background: var(--back-button-background);
    border: 1px solid var(--button-back-border);

    display: flex;
}

.button__back img {
    filter: var(--arrow-back-filter);
}

.button__tariffs {
    padding: 14px 20px;
    border: 1px solid var(--button-exit-border);
    background: transparent;
    outline: none;
    user-select: none;
    cursor: pointer;

    border-radius: 10px;

    color: var(--theme-bold-color);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.24px;

    width: calc(100% - 40px);

    text-decoration: none;
    display: block;
}

button.button__tariffs {
    width: 100%;
}

.settings-profile__tariff {
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(400px - 40px);
}

.settings-profile__tariffs-list {
    display: flex;
    flex-direction: row;
}

.tariffs-list__left {
    border-radius: 20px 0 0px 20px;
    width: calc(400px - 60px);
}

.tariffs-list__tariff-wrapper,
.tariffs-list__tariff-container {
    display: flex;
    flex-direction: column;
}

.tariffs-list__tariff-wrapper {
    align-items: start;
    gap: 16px;
}

.tariff-background__logo img,
.tariffs-list__logo {
    width: 153px;
    height: 28px;
}

.tariffs-list__right,
.tariffs-list__left {
    padding: 30px;
}

.tariffs-list__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 36px;

    width: calc(400px - 60px);
    border-radius: 0px 20px 20px 0px;
    border: 1px solid var(--theme-tariff-list-border); // #1E1E1E;
    background: var(--theme-tariff-background);
}

.tariffs-list__subscriptions {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.subscription__discount {
    padding: 8px 16px;
    background: linear-gradient(247deg, #ea384d 34.9%, #d31027 78.8%);
    color: white;
    border-radius: 20px;
}

.tariffs-list__subscription {
    color: var(--subscription-color); // #BABABC;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 16px 13px 16px 24px;
    border-radius: 15px;
    border: 1px solid var(--theme-tariff-list-border-bold);

    * {
        pointer-events: none;
    }

    &.tariff__selected {
        border: 1px solid var(--theme-tariff-list-border-selected);
    }
}

.subscription__price {
    font-size: 24px;
}

.subscription__title {
    font-size: 14px;
    min-height: 36px;
}

.subscription__period {
    font-size: 12px;
}

.tariffs-list__title,
.tariffs-list__price,
.tariffs-list__tariff-wrapper .tariff-content__description,
.tariffs-list__tariff-wrapper .tariff-features__feature,
.tariffs-list__tariff-wrapper .tariff-description__title {
    color: white;
}

.tariffs-list__title {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.tariffs-list__price {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 132%;
    letter-spacing: -0.24px;
}

.tariff-description__title {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.07px;
}

.subscription__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tariff__background,
.tariff-content__features,
.tariff-content__description,
.tariff-content__main,
.tariff__content,
.tariff__column {
    display: flex;
    flex-direction: column;
}

.tariff__column {
    border-radius: 30px;
    box-shadow: var(--settings-box-shadow); // 0px 0px 50px 0px rgba(0, 0, 0, 0.05);
}

.modal__button.button__tariff {
    width: 100%;
    color: white;
}

.tariff__content {
    justify-content: space-between;
    padding: 16px 30px 30px 30px;
    width: calc(100% - 60px);
    gap: 90px;
    border-radius: 0px 0px 20px 20px;

    background: var(--theme-tariff-background);
}

.tariff__background,
.tariff-content__features,
.tariff-content__description,
.tariff-content__main {
    align-items: start;
}

.tariff-content__description {
    padding-bottom: 24px;
    gap: 24px;
    border-bottom: 1px solid #eee;
}

.tariff-content__features {
    padding-top: 24px;
    gap: 16px;
}

.tariff__background {
    padding: 30px 30px 16px 30px;
    justify-content: space-between;
}

.tariff-background__price {
    color: var(--day-white, #fff);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 132%; /* 31.68px */
    letter-spacing: -0.24px;
}

.tariff-background__title {
    color: var(--day-white, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 19.6px */
}

.tariff-description__title {
    text-align: start;

    color: var(--theme-thin-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.07px;

    width: 80%;
}

.tariff-features__feature {
    display: flex;
    flex-direction: row;
    gap: 16px;
    color: var(--theme-bold-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 15.6px */
    letter-spacing: -0.06px;
    text-align: left;
}

.user-data__links img,
.tariff-features__feature img {
    filter: var(--theme-icon-filter);
}

.tariffs-list__tariff-wrapper .tariff-features__feature img {
    filter: none;
}

.button__exit:hover,
.button__tariffs:hover {
    background: var(--button-exit-hover-background);
    color: var(--button-exit-hover-color);
}

.docs__mobile {
    display: none;
}

.settings__modal-container {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: brightness(0.5);
    z-index: 9999;
    top: 0;
}

.settings__modal-visible {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.settings__modal-wrapper {
    margin: 25vh 37.5%;
    width: 25%;
    height: fit-content;
    background: var(--modal-background); //;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    color: white;
}

.modal__input-group {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;
    width: 100%;
}

.modal-input__email-change {
    position: relative;
}

.modal-input__file {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: calc(100% - 48px);
    border-radius: 15px;
    border: 1px solid #302f2f;
    padding: 16px 24px;
}

.modal-input__email-change {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.modal-input__file-types {
    color: #bababc;
}

.modal-input__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.modal-input__label {
    width: 100%;
    text-align: start;
    color: var(--modal-input-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.modal-input__input-field {
    background: var(--modal-input-background);
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(220, 219, 222, 0.5);

    padding: 10px 16px;
    width: calc(100% - 32px);

    color: var(--modal-input-color); //  #DCDBDE
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.modal-input__title-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
}

.modal-input__title-image {
    width: 20%;
}

.modal-input__input-field:-webkit-autofill,
.modal-input__input-field:-webkit-autofill:focus,
.modal-input__input-field:-webkit-autofill:hover,
.modal-input__input-field:-webkit-autofill:active {
    transition: background-color 50000s ease-in-out, color 50000s ease-in-out !important; // костыль для фикса автозаполнения в Chrome
}

.modal__button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding-top: 16px;
}

.modal__button {
    outline: none;
    padding: 10px 15px;
    width: 48%;
    border-radius: 12px;
    background: none;
    border: 1px solid transparent;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: -0.08px;
}

.button__cancel {
    border: 1px solid var(--modal-cancel-border); // #302F2F
    color: var(--theme-bold-color);
}

.button__full-width {
    width: 100%;
}

.button__cancel:hover {
    border: 1px solid transparent;
    color: var(--modal-cancel-hover-color);
    background: var(--modal-cancel-hover-background);
}

.button__save {
    color: var(--modal-save-color);
    background: var(--modal-save-background); // #EC5C48

    &-white {
        color: white;
    }
}

.button__save:disabled {
    opacity: 0.5;
}

.button__save:hover {
    background: var(--modal-save-hover-background); // #EC5C48
}

.progress__container {
    width: 80%;
    height: 4px;
    border-radius: 5px;
    background: #1e1e1e;
}

.progress__scale {
    height: 100%;
    width: 0;
    border-radius: 20px;
    background: #ec5c48;
}

.settings__modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px;

    gap: 16px;

    color: var(--theme-bold-color);
    &_sign {
        min-height: 500px;
    }
}

.settings__modal-content.settings__modal-content--light-black {
    color: var(--settings-modal-content-background-color);
}

.settings__exit {
    cursor: pointer;
    position: absolute;
    display: flex;
    right: -1rem;
    top: -1rem;
    width: 33px;
    height: 33px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: var(--button-exit-background);
}

.settings__exit img {
    filter: var(--button-exit-filter);
    width: 30%;
    height: 30%;
    pointer-events: none;
}

.modal__title {
    font-weight: 600;
    font-size: 18px;
}

.tariffs-list__tariff-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
    gap: 16px;
}

.avatar__preview {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-bottom: 20px;
}

.avatar__preview img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
}

.hint-modal {
    box-sizing: border-box;
    padding: 23px;
    background-color: var(--modal-background);
    border-radius: 20px;
    font-family: "Inter";
    font-size: 12px;
    color: var(--modal-input-color);
    position: absolute;
    font-weight: 600;
    width: 237px;
    top: -300%;
    left: -816%;
}

.radio-list {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
    &__item {
        display: flex;
        align-items: center;
        gap: 20px;
        input {
            width: 16px;
            height: 16px;
            border-radius: 3px;
            margin: 0;
            border: 5px solid #616161;
        }
        label {
            color: var(--theme-bold-color);

            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            letter-spacing: -0.21px;
        }
        .input-label {
            border-bottom: 2px var(--reset-color-bold) solid;
            border-top: none;
            border-left: none;
            border-right: none;
            padding-bottom: 3px;

            background-color: transparent;
            outline: none;
            color: var(--reset-color-bold);
            width: 100px;
            border-radius: 0;

            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            letter-spacing: -0.21px;
        }
    }
}

.double-label {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
    .second {
        color: #616161;

        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        letter-spacing: -0.18px;
    }
}

@media screen and (max-width: 1660px) and (min-width: 850px) {
    .settings__modal-wrapper {
        margin: 25vh 35%;
        width: 30%;
    }

    .tariffs-list__left {
        width: calc(230px - 60px);
    }

    .tariffs-list__right {
        width: calc(320px - 40px);
        padding: 30px 10px;
    }
}

@media screen and (max-width: 1660px) and (min-width: 1360px) {
    .settings-profile__tariff {
        width: 320px;
    }

    .tariffs-list__right {
        width: calc(396px - 40px);
    }
}

@media screen and (max-width: 1360px) and (min-width: 851px) {
    .settings-profile__tariff {
        width: 320px;
    }

    .tariffs-list__left {
        width: calc(238px - 60px);
    }

    .tariffs-list__right {
        width: calc(320px - 40px);
        padding: 30px 10px;
    }

    .tariffs-list__subscription {
        gap: 6px;
    }
}

@media screen and (max-width: 1660px) and (min-width: 851px) {
    .settings__profile-container {
        gap: 2vw;
        padding: 2vw 12px;
    }

    .settings-profile__user-data {
        padding: 2vw;
        width: fit-content;
    }

    .user-data__links {
        padding: 8px 1vw;
    }

    .user-data__info {
        font-size: 12px;
    }
}

@media screen and (max-height: 900px) {
    .settings__content-wrapper {
        gap: 0;
    }
}

.tariff__mobile {
    display: none;
}

.arrow__mobile-back,
.settings__text-mobile {
    display: none;
}

.title-text__content {
    max-width: 270px;
    word-wrap: break-word;
    text-align: left;
}

.transform__capitalize {
    text-transform: capitalize;
}

.reactEasyCrop_Container {
    max-height: 400px;
    margin: auto;
    margin-top: 60px;
}

.form__input-invalid {
    border: 1px solid #e95656 !important;
}

@media screen and (max-width: 850px) {
    .title-text__content,
    .user-data__links {
        font-size: 12px;
    }

    .modal-input__file {
        width: calc(100% - 20px);
        padding: 10px;
    }

    .settings__modal-wrapper {
        margin: 25vh auto;
        width: 95%;
    }
    .arrow__mobile-back {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;

        width: calc(100% - 24px);
        padding: 24px 12px 0 12px;

        text-decoration: none;

        color: var(--arrow-back-color);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 132%;
        letter-spacing: -0.18px;
    }

    .settings-profile__tariff-fullscreen .settings-profile__tariff {
        display: none;
    }

    .tariffs-list__tariff-content,
    .tariffs-list__tariff-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 100%;
    }

    .tariffs-list__tariff-container {
        height: 100%;
    }

    .tariffs-list__tariff-content {
        justify-content: start;
        align-items: start;
        gap: 16px;
    }

    .tariff-description__title {
        width: 100%;
    }

    .settings__text-mobile {
        display: block;

        color: var(--theme-bold-color);
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;

        padding: 24px 0;
    }

    .settings-profile__tariff-fullscreen .tariffs-list__left,
    .settings-profile__tariff-fullscreen .tariffs-list__right {
        padding: 24px 12px;
    }

    .settings-profile__tariff-fullscreen .tariffs-list__right {
        border-radius: 20px;
    }

    .settings__tariffs-list-container .tariff__column {
        width: 80vw;
        height: 70vh;
    }

    .tariffs-list__left,
    .tariff__column .tariff__content {
        height: 100%;
    }

    .settings__tariffs-list-container .tariff__content {
        border-radius: 20px;
    }

    .tariff__mobile {
        display: block;
        background: white;
        color: black;

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
    }

    .settings-profile__tariff-fullscreen {
        .tariffs-list__title,
        .tariffs-list__price,
        .tariffs-list__tariff-wrapper .tariff-content__description,
        .tariffs-list__tariff-wrapper .tariff-features__feature,
        .tariffs-list__tariff-wrapper .tariff-description__title {
            color: var(--tariff-open-color);
            text-align: start;
            width: 100%;
        }

        .tariffs-list__price {
            display: none;
        }
    }

    .button__back {
        display: none;
    }

    .settings__tariffs-list-container {
        overflow-x: scroll;
    }

    .settings__tariffs-list {
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 0 12px;
    }

    .tariffs-list__right,
    .tariffs-list__left {
        width: unset;
    }

    .tariffs-list__left {
        border-radius: 20px;
    }

    .tariffs-list__right {
        display: none;
    }

    .tariffs-list__tariff-open .tariffs-list__right {
        display: flex;
        flex-direction: column;
        padding: 12px 12px 24px 12px;
    }

    .settings-profile__tariffs-list {
        flex-direction: column;
        justify-content: start;
    }

    .settings-profile__tariff-fullscreen .settings-profile__tariffs-list {
        justify-content: space-between;
    }

    .settings__title {
        display: none;
    }

    .settings__content-wrapper {
        width: 100vw;
        overflow: hidden;
    }

    .settings__overflow-scroll {
        overflow-y: scroll;
    }

    .settings__profile-container {
        padding: 5%;
    }

    .settings-profile__tariff,
    .settings__profile-container {
        width: 100vw;
        gap: 20px;
        justify-content: start;
    }

    .settings__profile-container:not(.settings__tariffs-list-container) {
        padding-bottom: 0;
        padding-top: 0;
    }

    .settings-profile__tariff-fullscreen {
        position: absolute;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;

        padding: 0;
    }

    .settings-profile__tariff-fullscreen .tariffs-list__tariff-container {
        height: unset;
    }

    .settings-profile__tariff-fullscreen .tariffs-list__left,
    .settings-profile__tariff-fullscreen .tariff__column .tariff__content {
        height: unset;
    }

    .settings-profile__tariff-fullscreen .tariffs-list__left {
        background: transparent;
    }

    .settings-profile__tariff-fullscreen .tariff__mobile {
        display: none;
    }

    .settings-profile__tariff-fullscreen .tariffs-list__tariff-wrapper {
        height: 50%;
    }

    .settings-profile__user-data {
        padding: 24px 12px;
        width: 100vw;
        border-radius: 0;
    }

    .docs__mobile {
        display: flex;
    }

    .user-data__docs:not(.docs__mobile) {
        display: none;
    }

    .content__theme {
        width: 100%;
    }

    .content__theme .sidemenu-theme__wrapper {
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .settings-profile__user-data {
        box-shadow: none;
    }
}
