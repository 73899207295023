.monitoring__main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: calc(100vh - 43px);
    width: 72%;

    padding-top: 43px;
}

.monitoring__main-title,
.monitoring__main-menu {
    padding: 0 15px;
    margin: 0 15px;
}

.monitoring__main-title {
    color: var(--theme-bold-color);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    text-align: start;
}

.monitoring__main-menu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    gap: 14px;
}

.menu__filter,
.menu__sort,
.menu__view-type {
    display: flex;
    flex-direction: row;
    gap: 4px;

    border-radius: 20px;
    background: var(--search-background);
    color: var(--subscription-color);

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 16.8px */

    .filter__value {
        padding: 12px 20px;
        margin: 4px;

        cursor: pointer;
    }

    .filter__active {
        background: var(--theme-thin-color);
        color: white;
        border-radius: 20px;
        color: var(--modal-background);
    }

    .filter__sort {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: transform 0.2s ease-in-out;
        padding: 4px;

        cursor: pointer;
        border-right: 2px solid var(--filter-sort-border);

        img {
            transition: transform 0.2s ease-in-out;
            object-fit: cover;

            height: 19px;
            width: 19px;
            object-fit: cover;
            padding: 10px;
            background: var(--modal-background);
            border-radius: 50%;
        }

        &.order__asc img {
            transform: rotate(180deg);
        }
    }
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
    .menu__filter,
    .menu__sort,
    .menu__view-type {
        .filter__value {
            padding: 12px 6px;
            margin: 2px;
        }
    }

    .monitoring__main-menu {
        gap: 14px 7px;
    }
}

.menu__view-type,
.value__layout.filter__active {
    border-radius: 30px;
}

.menu__search {
    position: relative;
    width: 100%;

    .search__input {
        border-radius: 20px;
        border: 1px solid var(--day-grey-50-tertiary, #a1a0a3);
        outline: none !important;
        padding: 8px 16px;
        text-indent: 1.5rem;
        width: calc(100% - 32px);

        background: var(--search-background);
        color: var(--search-color);
    }

    .search__input:focus {
        border: 1px solid var(--day-grey-50-tertiary, #a1a0a3);
    }

    .search__image {
        position: absolute;
        left: 1rem;
        top: 8px;
    }
}

.monitoring__row {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;

    width: 100%;
}

.monitoring__actions {
    padding: 30px 50px;
    position: relative;
    width: 20vw;

    display: flex;
    flex-direction: column;
    background-color: var(--sidemenu-background);
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
    .monitoring__actions {
        padding: 30px 20px;
    }

    .actions__content {
        width: calc(20vw - 5px);
    }
}

.actions__content {
    width: calc(20vw - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 80px);
    position: fixed;
}

.actions__content-top {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.actions__title-small,
.actions__title-large {
    text-align: start;
}

.actions__title-small {
    color: var(--theme-bold-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.06px;
}

.actions__title-large {
    color: var(--theme-bold-color);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    min-height: 4em;
}

.actions__buttons-top,
.actions__buttons-bottom {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.actions__buttons-top {
    padding-top: 32px;
}

.actions__button {
    border-radius: 15px;
    border: 1px solid var(--actions-button-monitoring);
    background: var(--monitoring-contact-row-background-color);

    padding: 16px 24px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: var(--theme-bold-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.21px;

    text-decoration: none;

    .actions__button-icon {
        &_dark-theme {
            filter: none;
        }
    }

    &.button__disabled {
        opacity: var(--actions-button-disabled-opacity);
        color: var(--monitoring-button-color);

        .actions__button-icon {
            filter: brightness(0);
            &_dark-theme {
                filter: var(--theme-icon-filter);
            }
        }
    }

    &_dark-theme {
        &.button__disabled:hover {
            border: 1px solid var(--actions-button-monitoring);
        }

        &:hover {
            border: 1px solid #fff;
        }
    }
}

.side__transparent {
    opacity: .5;
}

.monitoring__main-content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    max-height: 80vh;
    overflow-y: scroll;

    padding: 13px 15px;
    margin: 0 15px;

    &.display__grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;

        .monitoring-contract__side {
            opacity: .5;
        }
    }
}

@media screen and (min-width: 1900px) {
    .monitoring__main-content.display__grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (min-width: 1441px) and (max-width: 1899px) {
    .monitoring__main-content.display__grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
    .monitoring__main-content.display__grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 1199px) {
    .monitoring__main-content.display__grid {
        display: flex;
        flex-direction: column;
    }
}

.monitoring__main-content::-webkit-scrollbar {
    width: 6px;
}

/* ползунок */
.monitoring__main-content::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-background-color);
    border-radius: 20px;
    border: 3px solid transparent;
}

/* бегунок ползунка */
.monitoring__main-content::-webkit-scrollbar-track {
    background: var(--theme-toggler-container-background);
}

.monitoring__main-content:hover::-webkit-scrollbar-thumb {
    background-color: rgba(12, 12, 12, 0.5);
}

.monitoring__contract-entity {
    transition: transform 0.2s ease-in-out, border 0.2s ease-in-out;
    border-radius: 20px;
    border: 1px solid var(--button-back-border);
    background: var(--monitoring-contact-row-background-color);

    padding: 8px 16px;

    &.contract__selected {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        transform: translateX(-10px);
        border: 1px solid #a1a1a1;
    }
}

.display__grid .monitoring__contract-entity {
    padding: 50px 20px;
    width: calc(100% - 48px);

    .monitoring-contract__row {
        position: relative;

        display: flex;
        flex-direction: column-reverse;

        .monitoring-contract__content-left,
        .monitoring-contract__content-right {
            flex-direction: column;
        }

        .monitoring-contract__content-left {
            .monitoring-contract__name {
                white-space: normal;
                text-overflow: unset;
                width: 10rem;
            }

            .monitoring-contract__icon {
                background: none;
                padding: 0;

                img {
                    width: 64px;
                    height: 64px;
                    object-fit: cover;
                }
            }
        }
    }

    .monitoring-contract__status-row {
        position: absolute;
        top: -42px;
        right: -12px;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
}

.monitoring-contract__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    gap: 16px;

    color: var(--theme-bold-color);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.monitoring-contract__icon {
    user-select: none;
    border-radius: 15px;
    background: var(--day-grey-5-default-bg, #f6f6f6);
    padding: 14px;

    display: flex;
    background: var(--monitoring-document-background);
}

.monitoring-status__status {
    padding: 5px 10px;
    border-radius: 48px;
    border: 1px solid #cbcbcb;
    font-size: 12px;

    &.status__control {
        border: 1px solid #4fad50;
    }
}

.monitoring-contract__status-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 16px;

    justify-content: flex-start;

    .monitoring-status__notifications {
        display: flex;
        flex-direction: row;
        gap: 5px;
        cursor: pointer;
    }

    .monitoring-notifications__name {
        color: #cbcbcb;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
}

.monitoring-contract__content-left {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.monitoring-contract__name {
    display: inline;
    max-width: 15vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    user-select: none;
    cursor: pointer;
}

.monitoring-contract__side {
    cursor: pointer;
    user-select: none;
    display: inline;
    max-width: 10vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.monitoring-contract__name-on-hover {
    transition: opacity 0.2s ease-in-out;
    position: absolute;
    left: 2rem;
    top: -1rem;

    background: rgba(0, 0, 0, 0.7);
    padding: 0.5rem;
    border-radius: 5px;
    color: white;
    opacity: 0;
    pointer-events: none;
    user-select: none;
}

.monitoring__contract-entity:first-of-type .monitoring-contract__name-on-hover {
    top: -5px;
}

.monitoring-contract__name-on-hover.hover {
    opacity: 1;
}

.actions__button {
    &-icon, &-text {
        pointer-events: none;
        user-select: none;
    }

    &-icon {
        filter: var(--theme-icon-filter);
    }
    &-text {
        filter: var(--theme-icon-filter-text);
    }
}

.document__viewer-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.modal-event-log__container {
    background: rgba(18, 18, 18, 0.32);
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: left;

    .modal-event-log__wrapper {
        max-width: 745px;
        min-width: 300px;
        padding: 45px 30px 40px 30px;
        border-radius: 20px;
        background: #fcfcfc;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .modal-event-log__header {
            margin: 0;

            h2 {
                margin: 0;
                color: #000;
                text-align: center;
                font-size: 1.125rem;
                font-weight: 600;
            }

            .modal-event-log__header--close-btn {
                position: absolute;
                top: -14px;
                right: -14px;
                width: 33px;
                height: 33px;
                cursor: pointer;
            }
        }

        .modal-event-log__events {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 60vh;
            padding-right: 10px;

            h3 {
                color: #000;
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 15.6px;
                letter-spacing: -0.06px;
                margin: 0 0 4px 0;
            }

            p {
                color: #222124;
                font-size: 0.875rem;
                line-height: 19.6px;
                margin: 0 0 10px 0;
                font-weight: 500;
            }

            a {
                color: #b82d22;
                font-size: 0.875rem;
                font-weight: 500;
                line-height: 19.6px;
                font-weight: 500;
                text-decoration-line: underline;
                margin: 0 0 10px 0;
                display: inline-block;
            }

            ul {
                margin: 0;
                list-style-type: "- ";
            }

            /* скроллбар, обязательно для работы стилей ползунка и бегунка */
            &::-webkit-scrollbar {
                width: 3.269px;
            }

            /* ползунок */
            &::-webkit-scrollbar-thumb {
                border-radius: 20px;
                width: 3.269px;
                background: rgba(18, 18, 18, 0.32);
            }

            /* бегунок ползунка */
            &::-webkit-scrollbar-track {
                width: 3.269px;
                border-radius: 20px;
                background: #f6f6f6;
            }

            .event {
                border-bottom: 1px solid #9d9d9d;
                padding-bottom: 20px;
                margin-bottom: 20px;
            }
        }

        .modal-event-log__buttons {
            display: flex;
            gap: 20px;
            padding-right: 10px;

            .modal-event-log__buttons-item {
                display: flex;
                width: 332px;
                padding: 16px 24px;
                justify-content: space-between;
                align-items: center;
                border-radius: 15px;
                border: 1px solid rgba(18, 18, 18, 0.32);
                background: #fff;
                cursor: pointer;
            }
        }
    }
}

.monitoring-status__date {
    color: var(--day-grey-50-tertiary, #a1a0a3);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    pointer-events: none;
    user-select: none;
}

.stop-control-modal {
    &__title {
        color: var(--theme-bold-color);

        text-align: center;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        text-align: left;
        margin-bottom: 10px;
    }
    &__subtitle {
        text-align: left;
        margin-bottom: 20px;
    }
    button {
        width: 100%;
        margin-top: 30px;
    }
}
