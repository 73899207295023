.claim-edit__container {
  display: flex;
  background: var(--settings-background);
  gap: 16px;
  padding-bottom: 16px;

  .claim-edit__back-wrapper {
    padding-top: 31px;
    text-align: left;
    margin-right: 24px;

    .claim-edit__back {
      padding: 14px 20px;
      border-radius: 12px;
      border: 0.5px solid var(--button-back-border);
      background: var(--back-button-background);
      cursor: pointer;

      img {
        display: block; // убрать дефолтный отступ под img
        filter: var(--arrow-back-filter);
      }
    }
  }

  .claim-edit__content {
    max-width: calc(100% - 500px);
    padding-top: 40px;
    flex-grow: 1;
    position: relative;
    text-align: left;

    h1,
    h2,
    h3,
    p {
      margin: 0;
    }

    h1 {
      margin-bottom: 30px;
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--theme-bold-color);
    }

    .claim-edit__content--form {
      display: flex;
      flex-direction: column;

      .claim-edit__content-title {
        margin-bottom: 20px;

        h2 {
          color: var(--theme-bold-color);
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 10px;
        }

        p {
          color: var(--theme-bold-color);
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: -0.21px;
        }
      }

      .claim-edit__content-rows {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 30px;

        .content-row {
          &.event__not-controlling {
            opacity: 0.5;
          }

          padding: 23px 30px 30px 30px;
          border-radius: 20px;
          border: 0.5px solid var(--claim-edit-row-border);
          background: var(--settings-modal-content-background-color);

          .content-row__top {
            flex-wrap: wrap;
            color: var(--theme-bold-color);
            font-size: 1rem;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.24px;
            display: flex;
            justify-content: space-between;
            gap: 20px;
            align-items: center;
            padding-bottom: 14px;

            h3 {
              width: 113px;
            }

            .content-row__top-inputs {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 20px;
              margin-left: auto;

              .content-row__top-inputs--price-block {
                display: flex;
                gap: 10px;
                align-items: center;
                color: #222124;
                font-family: Inter;
                font-size: 0.9375rem;
                font-weight: 400;
              }

              .content-row__top-inputs--price,
              .content-row__top-inputs--calendar,
              .content-row__top-inputs--document-name {
                height: 40px;
                box-sizing: border-box;
                padding: 10px 16px;
                border-radius: 10px;
                border: 0.5px solid var(--claim-send-input-color);
                background: var(--theme-toggler-container-background);
                color: var(--theme-bold-color);
              }

              .content-row__top-inputs--price {
                width: 140px;
              }

              .content-row__top-inputs--calendar {
                width: 140px;

                // Скрытие текстов в input type date
                &:in-range::-webkit-datetime-edit-year-field,
                &:in-range::-webkit-datetime-edit-month-field,
                &:in-range::-webkit-datetime-edit-day-field,
                &:in-range::-webkit-datetime-edit-text {
                  color: transparent;
                }
              }

              .content-row__top-inputs--document-name-block {
                position: relative;

                img {
                  position: absolute;
                  top: 6px;
                  right: 16px;
                  width: 24px;
                  height: 24px;
                }
              }

              .content-row__top-inputs--checkbox {
                width: 24px;
                height: 24px;
                padding: 0;
                border: none;
                outline: none;
                background: none;
              }
            }

            .content-row__top-actions {
              width: 200px;
              gap: 10px;
              display: flex;

              img {
                cursor: pointer;
              }

              .top-actions__save-button {
                &:hover {
                  box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 2px;
                }

                &:active {
                  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 4px;
                  border: 1px solid black;
                }

                text-align: center;
                flex-grow: 1;
                height: 40px;
                border-radius: 30px;
                border: 1px solid var(--claim-send-button-border);
                background: var(--settings-modal-content-background-color);
                color: var(--claim-send-button-color);
                font-family: Inter;
                font-size: 1rem;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: -0.24px;
                box-sizing: border-box;
              }

              .top-actions__save-button--red {
                border: 1px solid var(--claim-edit-button-red-background);
              }
            }
          }

          .content-row__bottom {
            display: flex;
            align-items: center;
            gap: 9px;

            p {
              color: var(--claim-edit-violance-color);
              font-size: 0.875rem;
              font-weight: 400;
              line-height: 21px;
              letter-spacing: -0.21px;
            }

            img {
              display: none;
            }

            &.violation {
              img {
                display: block;
              }

              p {
                color: #b82d22;
              }
            }
          }
        }
      }

      .claim-edit__content-actions {
        display: flex;
        gap: 16px;

        button {
          text-align: center;
          height: 44px;
          padding: 10px 20px;
          border-radius: 12px;
          outline: 0;
          font-size: 1rem;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.08px;
          font-family: Inter;
        }

        .content-actions__button--red {
          background: var(--theme-button-color-enabled);
          color: #fff;
          border: 0;
        }

        .content-actions__button--transparent {
          background: var(--theme-input-background);
          color: var(--claim-edit-button-text-color);
          border: 0.5px solid var(--button-exit-border);
        }
      }
    }
  }
}

.content-row__top-inputs--document-name {
  min-width: 400px;
}

@media screen and (max-width: 1440px) {
  .content-row__top {
    flex-direction: column;
    justify-content: start !important;
    align-items: start !important;
  }

  .content-row__top-inputs {
    width: 100%;
    flex-direction: row;
    justify-content: start;
    margin-left: 0;
    gap: 10px;
  }

  .content-row__top-inputs {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
  }

  .content-row__top-inputs > input,
  .content-row__top-inputs > div {
    width: 100% !important;

    .content-row__top-inputs--document-name {
      min-width: unset;
      width: 100%;
    }
  }

  .content-row__top-inputs > .content-row__top-inputs--price-block input {
    width: calc(100% - 5em) !important;
  }
}
