:root {
  --input-bg-color: #f6f6f6;
  --input-border-color: #a1a0a3;
  --input-color: #222124;
  --bounded-btn-color: #000;
}

[data-theme="dark"] {
  --input-bg-color: #222124;
  --input-border-color: #a1a0a3;
  --input-color: #ffffff;
  --bounded-btn-color: #ffffff;
}

.documents__panel {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background: var(--settings-background);

  &.panel__onload {
    filter: brightness(0.8);
    pointer-events: none;
  }

  &-preview {
    justify-content: center;
    height: 100vh;

    .documents__viewer-container {
      width: 60%;

      .documents__document-viewer {
        height: calc(90vh - 70px - 0.48rem) !important;
      }
    }
  }
}

.documents__panel .button__back {
  z-index: 100;
}

.documents__events-list,
.documents__sections-container {
  width: 35%;
}

.documents__sections-container {
  .button__back {
    position: relative;
    width: fit-content;
    top: 0;
    left: 0;
  }
}

.input__grey {
  background: transparent;
}

.documents__viewer-container {
  width: 50%;
}

.documents__viewer-title {
  padding-top: 25px;
  padding-bottom: 24px;
  line-height: 0rem;
  color: var(--theme-bold-color);
}

.documents__document-viewer {
  width: 100% !important;
  // padding: 50px 20px 0 20px;

  height: calc(100vh - 70px - 0.48rem) !important;
  overflow-y: scroll;

  border: none;
  outline: none;
}

.documents__sections-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 32px;

  color: var(--day-black, #000);
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 132%; /* 23.76px */
  letter-spacing: -0.18px;
}

#pdf-controls {
  // NB внутренний селектор библиотеки для обработки доков
  display: none;
}

.documents__document-viewer,
#header-bar {
  // NB внутренний селектор библиотеки для обработки доков
  background: none !important;
}

#file-name {
  // NB внутренний селектор библиотеки для обработки доков
  text-align: center;
}

.documents__events-list {
  display: flex;
  flex-direction: column;

  gap: 32px;

  height: calc(100vh - 100px);
  overflow-y: scroll;
}

.documents__events-column {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.column__group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.column__group-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.column__group-label {
  color: var(--day-grey-80-primary, #222124);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
  text-align: start;
}

.column__group-input-container {
  position: relative;
  width: 100%;
}

.event-sides__input,
.column__group-input {
  padding: 10px 16px;
  display: flex;
  position: absolute;
  width: calc(100% - 32px);
  border-radius: 10px;
  border: 1px solid var(--input-border-color);
  outline: none;

  overflow: hidden;
  color: var(--input-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: var(--input-bg-color);

  &.with__full-width {
    width: 100%;
    padding: 16px 10px;
    background: white;
  }
}

@media screen and (min-width: 1441px) {
  .event-sides__input,
  .column__group-input {
    padding: 10px;
  }
}

.documents__sections-container,
.documents__events-list {
  padding: 50px;
  background: var(--settings-background);
}

.documents__events-title {
  color: var(--day-black, #000);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 132%;
  letter-spacing: -0.18px;
}

.documents__event-sides {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.documents__events-element {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.event-sides__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.row__with-inn {
    flex-wrap: wrap;
    gap: 16px;

    .event-sides__select {
      width: 100%;
    }

    .event-sides__row {
      width: 100%;
    }

    input {
      display: inline;
      width: calc(50% - 34px);
      height: 53px;
      padding: 0 10px;
    }
  }
}

.event-sides__row .event-sides__select {
  width: calc(50% - 8px);
  position: relative;

  &.select__currency {
    width: 100%;
  }
}

.event-sides__input {
  width: calc(50% - 32px - 8px);
  position: relative;
}

.event-sides__row .event-sides__select.select__width-full {
  width: 100%;
}

.event-sides__select {
  padding: 16px 10px;

  overflow: hidden;
  color: var(--input-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  border-radius: 10px;
  border: 1px solid var(--input-border-color);
  background: transparent;
  outline: none;
}

.documents__bounded-title,
.documents__event-title {
  text-align: start;
  color: var(--theme-bold-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 132%;
  letter-spacing: -0.18px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 28px;
}

.documents__delete-custom-obligation {

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;

  border: none;
  outline: none;

  background: none;
  color: var(--theme-bold-color);
}

.event__delete-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  padding: 10px;
  border: 1px solid #A1A0A3;
  border-radius: 12px;

  color: var(--theme-bold-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.21px;

}

.select__date-type {
  width: 40%;
}

.input__datetime {
  display: inline;
  width: calc(50% - 34px);
}

.sides-input__small {
  width: calc(15% - 32px);
  text-indent: 0;
}

.event-sides__row .sides-select__small {
  width: calc(25% - 32px);
}

.row__wrappable {
  flex-wrap: wrap;
}

.documents__bounded-buttons {
  padding-top: 16px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.documents__bounded-buttons {
  .documents__button-specs,
  .documents__button-integration,
  .documents__button-reaction {
    border-radius: 15px;
    border: 1px solid var(--Linear, #e3495a);

    outline: none;
    background: none;
    padding: 16px 24px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--bounded-btn-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: -0.21px;

    * {
      pointer-events: none;
    }
  }

  .documents__button-text {
    text-align: start;
    max-width: 80%;
  }

  .documents__button-specs {
    width: 100%;
  }

  .documents__button-integration,
  .documents__button-reaction {
    width: 48%;
  }
}

.documents__button-control {
  padding: 16px 24px;
  outline: none;
  border-radius: 15px;
  border: 1px solid var(--day-alpha-grey-32, rgba(18, 18, 18, 0.32));
  background: var(--Linear, linear-gradient(236deg, #e3495a 0.15%, #b82d22 100%));
  color: white;
  line-height: 200%;
}

.documents__modal {
  display: none;

  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.4);

  z-index: 999;
}

.documents__modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
}

.documents__modal-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 48px;
  width: 60vw;

  border-radius: 20px;
  background: #fff;
}

.documents__modal.documents__modal-open {
  display: block;
}

.documents__modal.with__width-small .documents__modal-wrapper {
  min-width: 200px;
  width: 20vw;
  padding: 20px 48px;
}

.documents__modal-content {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 28px;
}

.documents__modal-title {
  color: var(--day-black, #000);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.documents__modal-reaction {
  display: flex;
  flex-direction: column;

  gap: 16px;
}

.modal-reaction__option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 16px;

  .modal-reaction__obligations,
  .reaction-option__side {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: start;
  }

  .reaction-email__title {
    color: var(--day-black, #000);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: -0.21px;
  }

  .reaction-obligations__title {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: -0.18px;
  }

  .reaction-obligations__list {
    display: flex;
    flex-direction: row;
    gap: 21px;
  }

  input {
    width: calc(100% - 32px);
  }
}

.modal-reaction__option:last-of-type {
  border-bottom: none;
}

.reaction-obligations__checkbox {
  display: flex;
  flex-direction: row;
  gap: 8px;

  label {
    color: var(--day-grey-80-primary, #222124);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    letter-spacing: -0.06px;
  }

  input {
    margin: 0;
  }
}

.button__add-obligation {
  img {
    pointer-events: none;
    user-select: none;
  }

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 20px;

  width: fit-content;
  padding: 0;
  border-radius: 30px;
  border: none;
  background: none;
  // border: var(--border-button-add-obligation); //  1px solid #B3B3B3;
  // background: var(--background-button-add-obligation); // #FFF;
  outline: none;

  color: var(--color-button-add-obligation); // #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 132%; /* 23.76px */
  letter-spacing: -0.18px;
  text-transform: uppercase;
}

.modal-reaction__option.option__disabled {
  opacity: 50%;
  pointer-events: none;
}

.reaction__button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .reaction-obligations__checkbox {
    justify-content: center;
  }
}

.button__save-reaction {
  padding: 14px 20px;
  border-radius: 12px;
  outline: none;
  border: none;
  background: var(--day-primary-50, #b82d22);

  color: var(--day-white, #fff);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;

  width: 50%;

  &.with__full-width {
    width: 100%;
  }
}

.documents__modal-integration {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  label {
    color: var(--day-grey-80-primary, #222124);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
}

.documents__modal-input-group {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  gap: 8px;
}

.input-group__hint {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 10px;
  align-items: center;

  overflow: hidden;
  color: rgba(34, 33, 36, 0.5);

  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.documents__modal-input-group input {
  width: calc(100% - 32px);
}

.documents__modal-input-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 24px;
}

.button__load {
  padding: 10px 20px;
  border-radius: 12px;
  border: 0.5px solid var(--day-alpha-grey-32, rgba(18, 18, 18, 0.32));
  background: var(--day-white, #fff);

  color: var(--day-black, #000);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.08px;
}

@media screen and (max-width: 1440px) {
  .event-sides__row.row__wrappable {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }

  .event-sides__row .event-sides__select.select__date-type,
  .event-sides__row .sides-select__small {
    width: 100%;
  }

  .sides-input__small {
    width: calc(100% - 32px);
  }

  .input__datetime {
    width: calc(100% - 34px);
  }
}

.documents__load {
  position: absolute;
  top: 25%;
  left: 25%;

  display: flex;
  width: 50%;
  justify-content: center;

  font-size: 25px;
  z-index: 1000;

  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: var(--theme-bold-color);

  &.relative {
    position: relative;
  }
}

.documents__load img {
  width: 119px;
  height: 119px;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
  animation: 2s infinite load-rotate;
}

@keyframes load-rotate {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
