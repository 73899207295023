.auth__logo {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.auth__token {
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  border-radius: 15px;
  background: var(--day-alpha-black, rgba(18, 18, 18, 0.79));
  color: var(--day-white, #FFF);
  font-size: 16px;
}

.auth__logo-image {
  width: 152px;
  height: 24px;
}

.auth__content {
  gap: 30px;
}

.form__fields {
  display: flex;
  flex-direction: column;
}

.form__register {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: calc(100% - 280px);
}

@media screen and (max-width: 1400px) {
  .form__register {
    width: calc(100% - 10vw);
  }
}

.form__fields {
  gap: 16px;
}

.form__input-hidden {
  display: none !important;
}

.form__continue_button {
  background: var(--theme-button-color-disabled);
  color: white;

  width: 100%;
  padding: 14px 20px;
  gap: 8px;
  border-radius: 12px;
  outline: none;
  border: none;
}

.form__input-group {
  display: flex;
  flex-direction: column;
  gap: 10px;

  position: relative;
  width: 100%;
}

.form__button-active {
  cursor: pointer;
  background: var(--theme-button-color-enabled);
}

.form__caption {
  color: var(--theme-bold-color);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 132%;
  letter-spacing: -0.18px;
  margin: 4px 0;
}

.form__input-field {
  overflow: hidden;
  color: var(--theme-thin-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 16px;
  border-radius: 10px;
  border: 1px solid #A1A0A3;
  background: var(--theme-input-background);
  outline: none;

  width: calc(100% - 32px);
}

.form__input-field:focus {
  background: var(--theme-input-background);
  color: var(--theme-thin-color);
}

.form__input-field:-webkit-autofill,
.form__input-field:-webkit-autofill:focus,
.form__input-field:-webkit-autofill:hover,
.form__input-field:-webkit-autofill:active,{
  transition: background-color 50000s ease-in-out, color 50000s ease-in-out !important; // костыль для фикса автозаполнения в Chrome
}

.form__links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 12px;
}

.form-links__link {
  color: var(--theme-bold-color);
  text-decoration: none;
  border-bottom: 1px solid var(--theme-bold-color);
}

.form__password-icon {
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 0.6em;

}

.form-links__auth {
  text-align: start;
  color: var(--theme-bold-color);
}

@media screen and (min-width: 900px) and (max-width: 1440px) {
  .form__links {
    font-size: 10px;
  }
}

.validator__message {
  display: none;

  position: absolute;
  width: fit-content;
  color: var(--validator-color);
  background: var(--validator-background);

  border-radius: 5px;

  padding: 16px 24px;

  top: -4rem;
  right: 0;

  z-index: 21;
}

.validator__message:hover,
.validator__message:focus {
  opacity: 0.5;
  user-select: none;
}

.validator__message-showing {
  display: flex;
}

.validator__marker {
  pointer-events: none;
  position: absolute;
  width: fit-content;
  height: fit-content;
  right: 6%;
  bottom: -1.8rem;
  transform: rotate(270deg);
}

@media screen and (max-width: 850px) {
  .validator__message {
    top: -3.6rem;
  }

  .validator__marker {
    right: 10%;
    bottom: -50%;

  }


  .modal__input-group .validator__marker {
    bottom: -40%;
    right: 5%;
  }

  .modal__input-group .modal-input__email-change .validator__message:nth-of-type(2) .validator__marker {
    bottom: -50%;
  }

  .modal__input-group .modal-input__email-change .form__password-icon {
    top: 1vh;
  }
}

.form__validation-icon {
  user-select: none;
  cursor: auto;
  background: var(--theme-background);
  z-index: 20;
}

.form__icon-disabled {
  display: none;
}

.form__input-invalid {
  border: 1px solid #E95656;
}

.modal__input-group .validator__message {
  top: -1.5rem;
  max-height: 2rem;
}

.modal__input-group .modal-input__email-change .validator__message {
  right: -0.5rem;
}

.modal__input-group .modal-input__email-change .validator__message:nth-of-type(1) {
  top: -4.5rem;
}

.modal__input-group .modal-input__email-change .form__password-icon {
  top: 1vh;
}

.modal__input-group .validator__marker {
  right: 1rem;
}

.settings__modal-content .modal__input-group .form__password-icon {
  right: 1vh;
  top: 4vh;
}

@media screen and (min-width: 1660px) {
  .modal__input-group .modal-input__email-change .validator__message:nth-of-type(1) {
    top: -3.5rem;
  }
}

.settings__modal-content .modal-input__email-change .form__password-icon {
  top: 1vh;
}

@media screen and (max-width: 1280px) {
  .settings__modal-content .modal__input-group .form__password-icon {
    top: 5.5vh;
  }

  .settings__modal-content .modal-input__email-change .form__password-icon {
    top: 1vh;
  }

  .modal__input-group .validator__message {
    top: -1rem;
  }

  .modal__input-group .modal-input__email-change .validator__message:nth-of-type(1) {
    top: -6vh;
  }

  .modal__input-group .validator__marker {
    right: 2vh;
  }

  .modal__input-group .form__password-icon {
    top: 6vh;
  }
}

@media screen and (max-width: 850px) {
  .settings__modal-content .modal__input-group .form__password-icon {
    top: 12vw;
  }
}


.modal__input-group .modal-input__email-change .validator__message:nth-of-type(2) {
  top: 0rem;
}

.modal__input-group .modal-input__email-change .form__password-icon:nth-of-type(2) {
  bottom: 0.6em;
  top: unset;
}

.modal__input-group .form__password-icon {
  bottom: 10%;
  right: 0.3rem;
  background: none;
}