.policy__container {
  background: var(--settings-background);
  width: 100%;
  overflow: hidden;
}

.policy__logo {
  display: flex;
  flex-direction: row;
}

.policy__wrapper {
  width: 100%;
}

.policy__wrapper .button__back {
  position: fixed;
  top: 39px;
  width: 24px;
}

.policy__content {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 48px;
  padding: 105px 0 48px 0;

  width: 60%;
  margin: 0 auto;
}

.policy__header-small,
.policy__header {
  width: 100%;
  margin: 0;
  color: var(--theme-bold-color);
  text-align: center;
}

.policy__header {
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.64px;
}

.policy__header-small {
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 26.4px */
  letter-spacing: -0.48px;
}

.policy__paragraph {
  margin: 0;
  color: var(--theme-thin-color);
  text-align: justify;

  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.policy__link {
  color: #0F6BE9;
  text-decoration: none;
}

.back__mobile {
  display: none;
}

@media screen and (max-width: 850px) {

  .back__mobile {
    display: block;
  }

  .policy__logo {
    width: 100%;
    justify-content: center;
  }

  .back__mobile img {
    filter: var(--arrow-back-filter);
    position: absolute;
    left: 0;
  }

  .policy__content {
    width: 80%;
    padding-top: 24px;
  }

  .policy__header {
    font-size: 18px;
  }

  .policy__paragraph,
  .policy__header-small {
    font-size: 14px;
  }
}