.sidemenu__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 140px;
  background: var(--sidemenu-background);
  overflow: hidden;

  transition: width .2s ease-in-out;

  &.expanded {
    width: 324px;
  }
}

.sidemenu__wrapper,
.sidemenu__theme {
  padding: 30px 25px 30px 25px;
}

.sidemenu__wrapper {
  display: flex;
  flex-direction: column;
  gap: 45px;

  position: fixed;
  width: calc(324px - 50px);
}

.sidemenu-user__wrapper {
  display: flex;
  flex-direction: row;
  padding: 16px;
  border-radius: 30px;
  border: 0.5px solid var(--settings-background);
  background: var(--settings-background);
  gap: 10px;
  align-items: center;

  .sidemenu-user__username {
    color: var(--theme-bold-color);
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: -0.18px;
  }
}

.sidemenu__elements {
  display: flex;
  flex-direction: column;
  gap: 16px;

  color: var(--theme-bold-color); // var(--day-grey-80-primary, #222124);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 132%; /* 23.76px */
  letter-spacing: -0.18px;

  .sidemenu-elements__element {
    justify-content: center;
  }
  &.expanded {
    .sidemenu-elements__element {
      justify-content: start;

    }
  }
}

.sidemenu-elements__element {
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
  padding: 10px 16px;
  border-radius: 30px;
  align-items: center;
  border: 1px solid transparent;
  color: var(--theme-bold-color);
  text-decoration: none;
}

.sidemenu-theme__toggle-slider {
  transition: transform .2s ease-in-out;
}

.sidemenu-elements__element img {
  filter: var(--theme-icon-filter);
}

.sidemenu-theme__dark .sidemenu-theme__toggle-slider {
  transform: translateX(100%);
}

.sidemenu-elements__element:hover {
  background: var(--sidemenu-element-background);
}

.button__create {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 14px 20px;
  width: 100%;

  border-radius: 30px;
  background: var(--theme-button-color-enabled);
  border: none;
  outline: none;
  color: white;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;

}

.sidemenu-user__avatar {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  object-fit: cover;

}

.sidemenu-theme__copyright {
  color: var(--theme-thin-color);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 13px */
  letter-spacing: -0.05px;
  text-align: left;
}

.sidemenu__user-container {
  text-decoration: none;
}

.sidemenu__theme {
  display: flex;
  flex-direction: column;
  gap: 24px;

  position: fixed;
  bottom: 0;
}

.sidemenu-theme__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;

  &.expanded {
    justify-content: start;
    padding: 0 0px;
  }
}

.sidemenu-theme__toggle-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60px;
  height: 30px;

  background: var(--theme-toggler-container-background);
  border-radius: 100px;

  padding: 2px;
}

.sidemenu-theme__toggle-slider {
  width: 19px;
  height: 19px;
  border-radius: 100%;
  background: var(--theme-toggler-background);

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;

  user-select: none;
}

.sidemenu-theme__current-theme {
  user-select: none;
  color: var(--theme-bold-color);
}

@media screen and (max-width: 850px) {
  .sidemenu__container {
    display: none;
  }
}