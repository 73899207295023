.take-to-court {
    display: flex;
    flex-direction: row;
    width: 100vw;

    &__sidemenu {
        width: 324px;
        height: 100vh;
    }

    &__container {
        width: calc(100vw - 324px);
        overflow: hidden;

        position: relative;
    }

    &__header {
        display: flex;
        align-items: center;
        background-color: var(--settings-background);
        padding: 31px 0 31px 40%;
        span {
            color: var(--day-black, #000);

            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 132%;
            letter-spacing: -0.18px;

            margin: 0;
            width: fit-content;
        }
        button {
            background-color: transparent;
            border: none;
            padding: 0;
        }
    }

    &__save {
        display: flex;
        gap: 8px;
        margin-left: 50%;
        span {
            color: var(--day-grey-50-tertiary, #a1a0a3);
            text-align: right;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
        }
    }

    .claim-create__viewer {
        max-height: 79.5vh;
    }
}
