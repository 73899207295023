.notification-list {
    position: fixed;
    bottom: 30px;
    right: 30px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.notification-item {
    border-radius: 20px;
    background: var(--day-white, #fff);
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);
    width: 345px;
    padding: 18px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    box-sizing: border-box;
}

.notification-item__title {
    display: flex;
    align-items: center;
    gap: 15px;

    h3 {
        color: var(--day-black, #000);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: -0.18px;
        margin: 0;
    }
}

.notification-item__text {
    color: var(--day-grey-80-primary, #222124);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
}

.notification-item__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    width: 100%;
}

.notification-item__time {
    color: #808080;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.notification-item__link {
    color: var(--day-black, #000);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.06px;
    text-decoration-line: underline;
}

.notification-item__button {
    border-radius: 12px;
    background: var(--day-bg-dark-primary, #252525);
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    outline: none;
    border: none;
}

.notification-item__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.notification-list__delete {
    border-radius: 12px;
    border: 0.5px solid var(--day-alpha-grey-32, rgba(18, 18, 18, 0.32));
    background: var(--day-black, #000);
    color: var(--day-white, #fff);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.24px;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.notification-list-item-enter {
    opacity: 0;
    transform: translateY(20px);
}

.notification-list-item-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
}

.notification-list-item-exit {
    opacity: 1;
    transform: translateY(0);
}

.notification-list-item-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 500ms, transform 500ms;
}
