.claim-send__container {
  display: flex;
  background: var(--settings-modal-content-background-color);
  gap: 16px;

  .claim-send__back-wrapper {
    padding-top: 31px;
    text-align: left;
    margin-right: 24px;

    .claim-send__back {
      padding: 14px 20px;
      border-radius: 12px;
      border: 0.5px solid var(--button-exit-border);
      background: var(--modal-input-background);
      cursor: pointer;

      img {
        display: block; // убрать дефолтный отступ под img
      }
    }
  }

  .claim-send__content {
    max-width: 1234px;
    padding-top: 40px;
    flex-grow: 1;
    position: relative;

    h1, h2, h3, p {
      margin: 0;
    }

    h1 {
      margin-bottom: 30px;
      font-size: 1.5rem;
      font-weight: 600;
      text-align: left;
      color: var(--theme-bold-color);
    }

    p {
      color: var(--theme-bold-color);
    }

    .claim-send__content--form {
      display: flex;
      flex-direction: column;

      .claim-send__documents-wrapper {
        border-radius: 20px;
        border: 0.5px solid var(--claim-send-block-border);
        background: var(--theme-input-background);
        padding: 35px 30px;
        margin-bottom: 10px;
        max-height: 314px;
        box-sizing: border-box;
        display: flex;

        .claim-send__documents {
          display: flex;
          flex-direction: column;
          flex-grow: 1;

          .claim-send__documents-content {
            overflow-y: auto;
            padding-right: 30px;

            /* скроллбар, обязательно для работы стилей ползунка и бегунка */
            &::-webkit-scrollbar {
              width: 6px;
            }

            /* ползунок */
            &::-webkit-scrollbar-thumb {
              border-radius: 20px;
              background-color: var(--scrollbar-thumb-background-color);
            }

            /* бегунок ползунка */
            &::-webkit-scrollbar-track {
              border-radius: 20px;
              background: var(--theme-toggler-container-background);
            }
          }

          .claim-send__add-document {
            padding: 8px 16.5px;
            border-radius: 30px;
            border: 1px solid var(--claim-send-button-border);
            background: var(--theme-input-background);
            color: var(--claim-send-button-color);
            text-align: center;
            font-weight: 500;
            line-height: 24px;
            font-size: 1rem;
            align-self: flex-start;
            cursor: pointer;

            &:hover {
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            }
          }
        }
      }

      .claim-send__delivery {
        border-radius: 20px;
        border: 0.5px solid var(--claim-send-block-border);
        background: var(--theme-input-background);
        padding: 35px 30px;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 30px;

        .claim-send__delivery-select select {
          display: flex;
          padding: 9px 16px;
          align-items: center;
          border-radius: 10px;
          border: 0.5px solid var(--claim-send-input-color);
          background: var(--modal-input-background);
          width: 345px;
          color: var(--claim-send-input-color);
          font-size: 0.9375rem;
          font-weight: 400;
        }

        .claim-send__delivery-recipients {
          display: flex;
          flex-direction: column;
          gap: 35px;

          .claim-send__delivery-recipient {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            flex-direction: column;

            .claim-send__delivery-recipient--name {
              display: flex;
              gap: 10px;

              .claim-send__delivery-add-recipient {
                padding: 8px 15px;
                border-radius: 30px;
                border: 1px solid var(--claim-send-button-border);
                background: var(--theme-input-background);
                color: var(--claim-send-button-color);
                font-size: 1rem;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: -0.24px;

                &:hover {

                }
              }
            }
          }
        }

        .claim-send__delivery-sender {
          display: flex;
        }

        input {
          max-width: 600px;
          flex-grow: 1;
          padding: 10px 16px;
          border-radius: 10px;
          border: 0.5px solid var(--claim-send-input-color);
          background: var(--monitoring-document-background);
          font-size: 0.9375rem;
          font-weight: 400;

          &::placeholder {
            color: var(--claim-send-input-color);
          }
        }
      }

      .claim-send__send-document {
        align-self: flex-start;
        padding: 10px 72.5px;
        border-radius: 12px;
        background: linear-gradient(236deg, #E3495A 0.15%, #B82D22 100%);
        color: #FFF;
        text-align: center;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.24px;
        border: none;
        outline: none;

        cursor: pointer;
        margin-bottom: 20px;

        &:hover {
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
      }
    }

    .claim-send__popup-wrapper {
      background-color: #2d2d2db5;
      padding: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 1;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: left;

      .claim-send__popup {
        max-width: 598px;
        padding: 30px;
        border-radius: 20px;
        background: var(--monitoring-contact-row-background-color);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-sizing: border-box;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        .claim-send__popup__header {
          margin: 0;

          h2 {
            margin: 0 0 16px 0;
            color: var(--theme-bold-color);
            text-align: center;
            font-size: 1.5rem;
            font-weight: 600;
          }

          .claim-send__popup__header--close-btn {
            position: absolute;
            top: -14px;
            right: -14px;
            width: 33px;
            height: 33px;
            cursor: pointer;
          }
        }

        .claim-send__popup__content {

          p {
            color: var(--claim-send-popup-color);
            text-align: center;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 19.6px;
          }
        }

        .claim-send__popup-actions {
          display: flex;
          justify-content: center;
          gap: 10px;

          .claim-send__popup-actions-btn {
            border-radius: 12px;
            border: 0;
            outline: 0;
            color: #FFF;
            font-size: 1rem;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.24px;
            padding: 14px 20px;
            box-sizing: border-box;

            &.claim-send__popup-actions-btn--red {
              background: var(--theme-button-color-enabled);
            }

            &.claim-send__popup-actions-btn--transparent {
              background: transparent;
              color: var(--theme-bold-color);
              border: 1px solid var(--claim-send-popup-button-border);
            }

            &.claim-send__popup-actions-btn--lg {
              margin-top: 36px;
              width: 100%;
            }

            &.claim-send__popup-actions-btn--sm {
              width: 92px;
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
}