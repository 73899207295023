.claim-send-document {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .claim-send-document__title {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-right: auto;
        text-align: left;

        p {
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: -0.21px;
        }

        .claim-send-document--auto-calculation {
            padding: 3px 27px;
            border-radius: 30px;
            background: linear-gradient(236deg, #e3495a 0.15%, #b82d22 100%);
            color: #fff;
            text-align: center;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.24px;
            border: none;
            outline: none;
        }
    }

    .claim-send-document__additional {
        display: flex;
    }

    .claim-send-document__amount {
        display: flex;
        align-items: center;
        margin-right: 20px;
        align-items: baseline;

        input {
            width: 30px;
            height: 30px;
            box-sizing: border-box;
            border-radius: 10px;
            border: 0.5px solid #222124;
            background: var(--button-exit-color);
            margin-right: 5px;
            color: var(--claim-send-input-color);
            text-align: center;
            font-size: 0.9375rem;
            font-weight: 400;

            // Скрыть стрелки вверх и вниз для input type="number"
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
        }

        p {
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: -0.21px;
        }
    }

    .claim-send-document__actions {
        display: flex;
        align-items: center; // без позиционирования img принимают размер флекс-контейнера
        width: 55px;
        gap: 12px;

        img {
            cursor: pointer;
            width: 24px;
            height: 24px;
            filter: var(--theme-icon-filter);
        }

        .claim-send-document__document-delete {
            width: 19px;
            height: 19px;
        }
    }
}
