.court-create-document {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .court-create-document__title {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-right: auto;
        text-align: left;

        p {
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: -0.21px;
        }

        .court-create-document--auto-calculation {
            padding: 3px 27px;
            border-radius: 30px;
            background: linear-gradient(236deg, #e3495a 0.15%, #b82d22 100%);
            color: #fff;
            text-align: center;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.24px;
            border: none;
            outline: none;
        }
    }

    .court-create-document__additional {
        display: flex;
    }

    .court-create-document__amount {
        display: flex;
        align-items: center;
        margin-right: 20px;
        align-items: baseline;

        input {
            width: 30px;
            height: 30px;
            box-sizing: border-box;
            border-radius: 10px;
            border: 0.5px solid #222124;
            background: var(--button-exit-color);
            margin-right: 5px;
            color: var(--claim-send-input-color);
            text-align: center;
            font-size: 0.9375rem;
            font-weight: 400;

            // Скрыть стрелки вверх и вниз для input type="number"
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
        }

        p {
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: -0.21px;
        }
    }

    .court-create-document__actions {
        display: flex;
        align-items: center; // без позиционирования img принимают размер флекс-контейнера
        width: 55px;
        gap: 12px;

        img {
            cursor: pointer;
            width: 24px;
            height: 24px;
        }

        .court-create-document__document-delete {
            width: 19px;
            height: 19px;
        }
    }
}

.court-create__container {
    display: flex;
    background: var(--settings-modal-content-background-color);
    gap: 16px;

    .court-create__back-wrapper {
        padding-top: 31px;
        text-align: left;
        margin-right: 24px;

        .court-create__back {
            padding: 14px 20px;
            border-radius: 12px;
            cursor: pointer;
            position: static;

            img {
                display: block; // убрать дефолтный отступ под img
            }
        }
    }

    .court-create__content {
        max-width: 1234px;
        padding-top: 40px;
        flex-grow: 1;
        position: relative;
        margin-bottom: 30px;

        h1,
        h2,
        h3,
        p {
            margin: 0;
        }

        h1 {
            margin-bottom: 30px;
            font-size: 1.5rem;
            font-weight: 600;
            text-align: left;
            color: var(--theme-bold-color);
        }

        p {
            color: var(--theme-bold-color);
        }

        .court-create__content--form {
            display: flex;
            flex-direction: column;

            .court-create__stage {
                h3 {
                    color: var(--theme-bold-color);
                    font-family: Inter;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-align: left;
                    margin-bottom: 8px;
                }

                h5 {
                    color: var(--day-black, #000);
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-align: left;
                }

                &_send {
                    .content {
                        display: flex;
                        gap: 10px;
                        flex-direction: column;
                    }
                }
            }

            .court-create__documents-wrapper {
                border-radius: 20px;
                border: 0.5px solid var(--claim-send-block-border);
                background: var(--theme-input-background);
                padding: 35px 30px;
                margin-bottom: 10px;
                max-height: 314px;
                box-sizing: border-box;
                display: flex;

                .court-create__documents {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;

                    .court-create__documents-content {
                        overflow-y: auto;
                        padding-right: 30px;
                        margin-bottom: 24px;

                        /* скроллбар, обязательно для работы стилей ползунка и бегунка */
                        &::-webkit-scrollbar {
                            width: 6px;
                        }

                        /* ползунок */
                        &::-webkit-scrollbar-thumb {
                            border-radius: 20px;
                            background-color: var(--scrollbar-thumb-background-color);
                        }

                        /* бегунок ползунка */
                        &::-webkit-scrollbar-track {
                            border-radius: 20px;
                            background: var(--theme-toggler-container-background);
                        }
                    }

                    .court-create__add-document {
                        padding: 8px 16.5px;
                        border-radius: 30px;
                        border: 1px solid var(--claim-send-button-border);
                        background: var(--theme-input-background);
                        color: var(--claim-send-button-color);
                        text-align: center;
                        font-weight: 500;
                        line-height: 24px;
                        font-size: 1rem;
                        align-self: flex-start;
                    }
                }
            }

            .court-create__delivery {
                border-radius: 20px;
                border: 0.5px solid var(--claim-send-block-border);
                background: var(--theme-input-background);
                padding: 35px 30px;
                text-align: left;
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-bottom: 30px;

                .court-create__delivery-select select {
                    display: flex;
                    padding: 9px 16px;
                    align-items: center;
                    border-radius: 10px;
                    border: 0.5px solid var(--claim-send-input-color);
                    background: var(--modal-input-background);
                    width: 345px;
                    color: var(--claim-send-input-color);
                    font-size: 0.9375rem;
                    font-weight: 400;
                }

                .court-create__delivery-recipients {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .court-create__delivery-recipient {
                        display: flex;
                        gap: 10px;
                        flex-wrap: wrap;
                        flex-direction: column;

                        .court-create__delivery-recipient--name {
                            display: flex;
                            gap: 10px;

                            .court-create__delivery-add-recipient {
                                padding: 8px 15px;
                                border-radius: 30px;
                                border: 1px solid var(--claim-send-button-border);
                                background: var(--theme-input-background);
                                color: var(--claim-send-button-color);
                                font-size: 1rem;
                                font-weight: 500;
                                line-height: 24px;
                                letter-spacing: -0.24px;
                            }
                        }
                    }
                }

                .court-create__delivery-sender {
                    display: flex;
                }

                input {
                    max-width: 600px;
                    flex-grow: 1;
                    padding: 10px 16px;
                    border-radius: 10px;
                    border: 0.5px solid var(--claim-send-input-color);
                    background: var(--monitoring-document-background);
                    font-size: 0.9375rem;
                    font-weight: 400;

                    &::placeholder {
                        color: var(--claim-send-input-color);
                    }
                }
            }

            .court-create__send-document {
                align-self: flex-start;
                padding: 10px 72.5px;
                border-radius: 12px;
                background: linear-gradient(236deg, #e3495a 0.15%, #b82d22 100%);
                color: #fff;
                text-align: center;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: -0.24px;
                border: none;
                outline: none;
            }
        }

        .court-create__popup-wrapper {
            background: var(--claim-send-popup-overlay-background);
            padding: 20px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            z-index: 1;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: left;

            .court-create__popup {
                max-width: 598px;
                padding: 30px;
                border-radius: 20px;
                background: var(--monitoring-contact-row-background-color);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                box-sizing: border-box;

                .court-create__popup__header {
                    margin: 0;

                    h2 {
                        margin: 0 0 16px 0;
                        color: var(--theme-bold-color);
                        text-align: center;
                        font-size: 1.5rem;
                        font-weight: 600;
                    }

                    .court-create__popup__header--close-btn {
                        position: absolute;
                        top: -14px;
                        right: -14px;
                        width: 33px;
                        height: 33px;
                        cursor: pointer;
                    }
                }

                .court-create__popup__content {
                    p {
                        color: var(--claim-send-popup-color);
                        text-align: center;
                        font-size: 0.875rem;
                        font-weight: 500;
                        line-height: 19.6px;
                    }
                }

                .court-create__popup-actions {
                    display: flex;
                    justify-content: center;
                    gap: 10px;

                    .court-create__popup-actions-btn {
                        border-radius: 12px;
                        border: 0;
                        outline: 0;
                        color: #fff;
                        font-size: 1rem;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: -0.24px;
                        padding: 14px 20px;
                        box-sizing: border-box;

                        &.court-create__popup-actions-btn--red {
                            background: var(--theme-button-color-enabled);
                        }

                        &.court-create__popup-actions-btn--transparent {
                            background: transparent;
                            color: var(--theme-bold-color);
                            border: 1px solid var(--claim-send-popup-button-border);
                        }

                        &.court-create__popup-actions-btn--lg {
                            margin-top: 36px;
                            width: 100%;
                        }

                        &.court-create__popup-actions-btn--sm {
                            width: 92px;
                            margin-top: 16px;
                        }
                    }
                }
            }
        }
    }
}

.court-create {
    &__load-button {
        padding: 8px 16.5px;
        border-radius: 30px;
        border: 1px solid var(--claim-edit-button-red-background);
        background: var(--theme-input-background);
        color: var(--claim-send-button-color);
        text-align: center;
        font-weight: 500;
        line-height: 24px;
        font-size: 1rem;
        align-self: flex-start;
        width: 345px;
    }
}

.court-create__btns {
    display: flex;
    align-items: center;
    gap: 10px;
}

.court-create__title {
    display: flex;
    align-items: center;
    gap: 10px;
}
