.autotemplate__input-group {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;

    gap: 8px;
}

.autotemplate__input-container {
    display: flex;
    flex-direction: row;

    position: relative;

    width: 100%;
}

.autotemplate__input-icon {
    position: absolute;
    left: 0.5rem;
    top: 25%;
}

.autotemplate__input-field {
    text-indent: 2rem;
    border-radius: 10px;
    border: 0.5px solid var(--day-grey-50-tertiary, #a1a0a3);
    background: var(--day-grey-5-default-bg, #f6f6f6);

    padding: 10px 16px;

    width: 100%;

    overflow: hidden;
    color: var(--day-grey-70-secondary, #5f5f61);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &[type="datetime-local"] {
        text-indent: 1rem;
    }
}

fieldset {
    margin: 0;
    padding: 0;
    border: none !important;

    display: flex;
    flex-direction: column;

    & > div {
        display: flex;
        flex-direction: row;
        padding-top: 8px;
        gap: 8px;
    }
}

.autotemplate__label {
    color: var(--day-grey-80-primary, #222124);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.documents__events-list.events__autotemplate {
    width: 25%;
}

.value__layout {
    padding: 5px !important;
    border-radius: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
        pointer-events: none;

        width: 16px;
        height: 16px;
        object-fit: cover;
        padding: 8px;
    }
}

.value__layout.filter__active img {
    filter: invert(1);
}

.value__layout.filter__active .dark_theme {
    filter: none;
}

.monitoring__row {
    justify-content: center;
}

.filter__template-type {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 8px;

    img {
        pointer-events: none;
    }
}

.filter__template-type.filter__active {
    img {
        filter: invert(1);
    }
}
