@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url('/public/fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url('/public/fonts/Inter-Medium.ttf') format('truetype');
}

body {
  font-family: 'Inter', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.auth__side-background {
  background: url("/public/img/auth__background.png") no-repeat 0% 0%;
  background-size: cover;
}

[data-theme='dark'] .auth__side-background {
  background: url("/public/img/auth__background-dark.png") no-repeat 0% 0%;
}

.tariff__background {
  border-radius: 20px 20px 0px 0px;
  height: 147px;
}

.tariff-type__demo {
  background: url('/public/img/tariffs/tariff__background-demo.png') no-repeat 0% 0%;
  background-size: cover;

}

.tariff-type__personal {
  background: url('/public/img/tariffs/tariff__background-personal.png') no-repeat 0% 0%;
  background-size: cover;

}

.tariffs-list__left {
  background: url('/public/img/tariffs/tariff__background-business.png') no-repeat 0% 0%;
  background-size: cover;
}